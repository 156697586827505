<template>
    <v-container>
        <v-row align="center" justify="center">
            <v-col align="center" justify="center">
                <v-container style="width: 320px;">
                   <v-row>
                     <v-col align="center" justify="center">
                       <span>{{ $t('register.verifyingEmail') }}</span>
                     </v-col>
                   </v-row>
                </v-container>
            </v-col>
        </v-row>
    </v-container>
</template>


<script>
import { mapGetters } from "vuex";
import i18n from "@/plugins/i18n";
import { api } from "@/helpers/ApiCalls";

export default {
  name: "EmailVerification",

  data() {
    return {};
  },

  computed: {
    ...mapGetters(["languages", "axiosSource"]),
    params() {
      const search = location.search.substring(1);
      return JSON.parse('{"' + search.replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
    },
  },

  methods: {
    emailVerification() {
      const params = this.params;

      if (!params) {
        return
      }

      const form = {
        verificationCode: params.code,
        lang: params.lang
      };
      
      this.$store.dispatch("emailVerification", form).then(
        (response) => {
          const notification = {
            show: true,
            result: true,
            message: response.message,
            delay: 8000,
          };

          this.$store.dispatch("showSnackbar", notification);
          this.redirectToHome();
        },
        (error) => {
          this.loading = false;

          let errorMessage;
          if (
            this.axiosSource &&
            this.axiosSource.token &&
            this.axiosSource.token.reason &&
            this.axiosSource.token.reason.message
          ) {
            errorMessage = this.axiosSource.token.reason.message;
          } else {
            errorMessage = error.message;
          }

          const notification = {
            show: true,
            result: false,
            message: errorMessage,
          };

          this.$store.dispatch("showSnackbar", notification);

          if (api.authenticationError(error.code)) {
            this.redirectToHome();
          } else {
            this.redirectToHome();
          }
        }
      );
    },
    redirectToHome() {
      setTimeout(function () {
        document.location.href = "/";
      }, api.authErrorRedirectTimeout);
    },
    changeLanguage(language) {
      this.$store.dispatch("changeLanguage", { language: language, reload: true })
        .then(() => {
          i18n.locale = language.locale;
          this.emailVerification();
        });
    },
  },

  mounted() {
      this.changeLanguage({ locale: this.params.lang });
  },

};
</script>
